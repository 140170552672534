import axios from "axios";

const http = axios.create({
  // baseURL: "http://192.168.1.77:8080",
  // baseURL: "http://192.168.1.188:18700/",
  baseURL: "https://api.polysmartchain.com",

  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
  timeout: 50000,
});

http.interceptors.request.use(function(config) {
  if (sessionStorage.getItem("Token") != null) {
    // config.headers.Authorization = sessionStorage.getItem('Token')
    config.headers["Authorization"] =
      "Bearer " + sessionStorage.getItem("Token");
  }
  config.headers["API-KEY"] = "3660BB2919A0CC91";

  return config;
});

http.interceptors.response.use(
  function(response) {
    return response.data;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default http;
